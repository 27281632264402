<template>
    <div class="container" v-loading="loading">
        <back pre />
        <div class="job-list" v-if="!loading">
            <div class="dept-name">
                {{ jobDept.name }}
            </div>
            <div class="head-box">
                <el-select v-model="jobTimeId" placeholder="请选择时间" style="width: 260px;" @change="jobTimeIdChange">
                    <el-option v-for="item in timeList" :key="item.id" :label="item.desc" :value="item.id">
                    </el-option>
                </el-select>
                <el-button style="margin-left: 20px;" type="primary" icon="el-icon-plus"
                    @click="addJobHandle">新增</el-button>
            </div>
            <div class="job-table">
                <el-table :data="list" style="width: 100%" stripe>
                    <el-table-column prop="title" label="工作事项" header-align="center" width="300">
                    </el-table-column>
                    <el-table-column prop="people" label="跟进人" header-align="center" align="center" width="200">
                    </el-table-column>
                    <el-table-column prop="content" label="工作反馈" header-align="center" width="auto">
                    </el-table-column>
                    <el-table-column label="操作" header-align="center" width="200">
                        <template slot-scope="scope">
                            <div style="display: flex;align-items: center;justify-content: space-around;">
                                <el-button type="primary" icon="el-icon-edit" size="small"
                                    @click="editJobHandle(scope.row)">修改</el-button>

                                <el-button slot="reference" type="danger" icon="el-icon-delete" size="small"
                                    @click="remove(scope.row)">删除</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-dialog :title="form.id ? '编辑' : '新增' + '工作内容'" :visible.sync="dialogFormVisible"
            :close-on-click-modal="false">
            <el-form :model="form">
                <el-form-item label="工作事项" label-width="auto">
                    <el-input v-model="form.title" autocomplete="off" clearable></el-input>
                </el-form-item>
                <el-form-item label="跟进人" label-width="auto">
                    <el-input v-model="form.people" autocomplete="off" clearable></el-input>
                </el-form-item>
                <el-form-item label="工作反馈" label-width="auto">
                    <el-input type="textarea" :rows="4" placeholder="请输入工作反馈" v-model="form.content" clearable>
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    jobTimeList,
    jobDeptDetail,
    jobList,
    addJob,
    editJob,
    delJob
} from "@/api";
import back from "@/components/back";
export default {
    components: {
        back,
    },
    data() {
        return {
            loading: true,
            dialogFormVisible: false,
            timeList: [],
            jobDept: null,
            jobTimeId: null,
            list: [],
            form: {}
        }
    },
    methods: {
        remove(row) {
            this.$confirm('确认删除该数据?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delJob(row.id).then(res => {
                    if (res.code == 200) {
                        this.$message.success('删除成功');
                    } else {
                        this.$message.error(res.msg);
                    }
                    this.jobTimeIdChange()
                })
            }).catch(() => {
            });
        },
        addJobHandle() {
            this.form = {}
            this.dialogFormVisible = true
        },
        editJobHandle(row) {
            this.form = JSON.parse(JSON.stringify(row))
            this.dialogFormVisible = true
        },
        async getJobTimeList() {
            await jobTimeList().then(res => {
                console.log(res);
                this.timeList = res.data
                this.jobTimeId = res.data[0].id

            })
        },
        async getJobDeptDetail() {
            await jobDeptDetail(this.$route.params.deptId).then(res => {
                this.jobDept = res.data
            })
        },
        async jobTimeIdChange() {
            console.log(this.jobTimeId);
            await jobList({
                jobTimeId: this.jobTimeId,
                jobDeptId: this.$route.params.deptId
            }).then(res => {
                console.log(res);
                this.list = res.data
            })
        },
        submit() {
            console.log(this.form);
            if (!this.form.title || !this.form.people || !this.form.content) {
                this.$message.warning('工作事项、跟进人、工作反馈为必填项');
                return
            }
            if (this.form.id) {
                editJob(this.form).then(res => {
                    if (res.code == 200) {
                        this.form = {}
                        this.dialogFormVisible = false
                        this.$message.success('修改成功');
                    } else {
                        this.$message.error(res.msg);
                    }
                    this.jobTimeIdChange()
                })
            } else {
                this.form.jobDeptId = this.$route.params.deptId
                this.form.jobTimeId = this.jobTimeId
                addJob(this.form).then(res => {
                    if (res.code == 200) {
                        this.form = {}
                        this.dialogFormVisible = false
                        this.$message.success('添加成功');
                    } else {
                        this.$message.error(res.msg);
                    }
                    this.jobTimeIdChange()
                })
            }
        }
    },
    async created() {
        await this.getJobTimeList()
        await this.getJobDeptDetail()
        await this.jobTimeIdChange()
        this.loading = false
    }
}
</script>

<style lang="scss">
.el-table__header-wrapper {
    .el-table__cell {
        background-color: #dbeeff !important;
        color: #000000 !important;
        border: 1px solid rgba(0, 0, 0, .2) !important;
        height: 60px;
        font-size: 18px;
    }
}
</style>
<style lang="scss" scoped>
.container {
    background-size: 1000%;
    animation: bganimation 60s infinite;
    background-attachment: fixed;
    background-image: linear-gradient(125deg,
            #e4ffcd,
            #6dd5fa,
            rgba(34, 209, 113, 0.3),
            #e4ffcd,
            #c7f1e7,
            #b9cfff);
    width: 100vw;
    height: 100vh;

    .job-list {
        overflow: auto;
        width: 80%;
        height: 80%;
        padding: 20px;
        position: absolute;
        background-color: rgba(228, 239, 255, 0.7);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);

        .dept-name {
            font-size: 26px;
            font-weight: bold;
            height: 6%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .head-box {
            width: 100%;
            padding: 0 0 10px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            border-bottom: 1px solid rgba(0, 0, 0, .3);
        }

        .job-table {
            width: 100%;
            padding: 10px 0;
        }
    }
}
</style>